import HttpRequest from '@/http/httpClass';

import { METRICS } from '@/http/endpoints';
import { Actions } from '@/store/v1/store';

const actions: Pick<Actions, 'getUserConversionRate' | 'getUserVisibility'> = {
  getUserConversionRate(_, options) {
    const { fromDate, toDate } = options;
    const periodType = options.periodType;

    const request = new HttpRequest(
      METRICS.CONVERSION_RATE({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
        periodType,
      }),
      'GET',
      true
    );

    return request.send();
  },
  getUserVisibility(_, options) {
    const { fromDate, toDate } = options;
    const periodType = options.periodType;

    const request = new HttpRequest(
      METRICS.VISIBILITY({
        fromDate: fromDate.replaceAll('/', '-'),
        toDate: toDate.replaceAll('/', '-'),
        periodType,
      }),
      'GET',
      true
    );

    return request.send();
  },
};

export default actions;

<template>
  <div class="sidebar__wrapper">
    <Transition name="slide-in" appear>
      <nav v-if="shouldShowSideBar" class="sidebar" key="sidebar">
        <div v-for="list in categoriesList" :key="list.title">
          <h3 class="sidebar__title">{{ list.title }}</h3>
          <ul class="sidebar__links">
            <li class="sidebar-link-wrapper" v-for="link in list.links" :key="link.title">
              <SidebarLink
                v-if="canViewLink(link)"
                :url="link.url"
                :label="$t(link.title!)"
                :link="link"
                :disabled="routeIsDisabled(link)"
                :opened-children="openedLink?.url === link.url"
                @close="() => $emit('close')"
                @openChildren="(link) => handleOpenChildren(link)"
              >
                <component :is="link.icon" />
              </SidebarLink>
            </li>
          </ul>
        </div>
      </nav>
    </Transition>
    <div
      v-if="shouldShowSideBar"
      :class="['sidebar__overlay', { 'sidebar__overlay--active': isOverlayVisible }]"
      @click="$emit('close')"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SidebarLink from './SidebarLink.vue';

import { categoriesList } from './sidebar.config';

import { isSuperUserLocked, isSuperUser } from '@/utils/superUser';

import { isLockedByRole, isLockedForZonesoft, userHasAnyMatchingRole } from '@/utils/roles';
import { ChildRoute, SideBarRoute } from './Sidebar';
import { useFeatureFlagsStore } from '@/store/v2';

const bodyElement = document.querySelector('body');

export default defineComponent({
  components: {
    SidebarLink,
  },
  props: {
    shouldShowOverlay: {
      type: Boolean,
      default: false,
    },
    shouldShowSideBar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showChilds(childs: ChildRoute[]) {
      // show childs if route is one of the childs
      return childs.some((child) => this.$route.path.includes(child.path));
    },
    routeIsDisabled(route: SideBarRoute) {
      if (!route.allowedRoles) {
        return false;
      }

      return !userHasAnyMatchingRole(route.allowedRoles);
    },
    handleOpenChildren(link: any) {
      if (link.url === this.openedLink?.url) {
        this.openedLink = null;
      } else {
        this.openedLink = link;
      }
    },
    hasFeatureFlag(link: any) {
      if (!('requiresFlag' in link)) {
        return true;
      }

      const featureFlagStore = useFeatureFlagsStore();

      return featureFlagStore.featureFlags?.some?.((ff) => ff.name == link.requiresFlag);
    },
    canViewLink(link: any) {
      if (link.hidden) {
        // Hide if meta.hidden is true
        return false;
      }

      if (this.isSuperUser) {
        // Superuser should be able to view everything
        return true;
      }

      if (!this.hasFeatureFlag(link)) {
        // Hide if user does not have feature flag
        return false;
      }

      if (isSuperUserLocked(link)) {
        // Hide if locked for super user
        return false;
      }

      if (isLockedForZonesoft(link)) {
        // Hide if locked for zonesoft
        return false;
      }

      if (isLockedByRole(link)) {
        // Hide if locked by role
        return false;
      }

      // User is not sudo, so hide sudo-only features
      return !link.isSuperUserLocked;
    },
  },
  data() {
    return {
      openedLink: null as any,
    };
  },
  computed: {
    isSuperUser,
    isOverlayVisible() {
      return this.shouldShowOverlay && this.shouldShowSideBar;
    },
    categoriesList() {
      return categoriesList(this.$store.getters.currentLanguage);
    },
  },
  watch: {
    shouldShowSideBar(isOpen: boolean) {
      if (isOpen) {
        bodyElement?.classList.add('fixed__body');
      } else {
        bodyElement?.classList.remove('fixed__body');
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.sidebar-link-wrapper {
  &:first-of-type {
    margin-top: var(--spacing-xxs);
  }
}
.sidebar {
  --side-bar-width: 20vw;

  --min-sidebar-width: 260px;
  --max-sidebar-width: 330px;
}

.sidebar {
  position: absolute;
  top: 62px; //FIXME: Temporary until account menu is implemented
  left: 0;

  display: flex;
  flex-direction: column;
  max-width: 80vw;
  height: calc(100vh - 62px);

  padding: var(--spacing-xs) var(--spacing-s);

  background-color: var(--color-white);
  border-right: 2px solid var(--brand-grey);

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  overflow: auto;

  z-index: 14;

  &__overlay {
    position: absolute;
    top: 62px; //FIXME: Temporary until account menu is implemented
    left: 0;

    width: 100%;
    height: calc(100vh - 62px);

    background-color: rgba(0, 0, 0, 0.35);
    z-index: 13;

    opacity: 0;
    overflow: auto;
    transition: opacity 100ms ease-in-out;

    &--active {
      opacity: 1;
    }
  }
  &__title {
    @include brand-font-m;
    @include font-medium;

    color: var(--text-color);
    text-transform: uppercase;
  }
  &__links {
    margin-bottom: var(--spacing-xxs);
  }
  &__mobile-title {
    margin-bottom: var(--spacing-xxs);

    text-align: center;
  }
  &__wrapper {
    .slide-in-enter-active,
    .slide-in-leave-active {
      transform: translateX(0);
      transition: transform 300ms ease-in-out;

      @include breakpoint-from('smallDesktop') {
        transition: none;
      }
    }

    .slide-in-enter,
    .slide-in-leave-to {
      transform: translateX(-100%);
    }
  }

  @include breakpoint-from('smallDesktop') {
    position: static;

    width: var(--side-bar-width);
    min-width: var(--min-sidebar-width);
    max-width: var(--min-sidebar-width);
    height: 100%;

    padding: 0;

    &__mobile-title {
      display: none;
    }
    &__overlay {
      display: none;
    }
    &__title {
      margin-left: var(--spacing-s);
    }
    &__links {
      margin-bottom: var(--spacing-s);
    }
  }
}
</style>

import { App } from 'vue';
import { Place, Prediction } from './_types';

interface InstallOptions {
  paths: string[];
  apiKey: string;
}

const getPlaceDetails = (placeId: string): Promise<Place> => {
  return new Promise((resolve) => {
    const place = new window.google.maps.places.PlacesService(document.createElement('div'));
    place.getDetails(
      {
        placeId,
        fields: ['geometry', 'name', 'formatted_address'],
        openNow: true,
      },
      (placeResult: Place) => {
        resolve(placeResult);
      },
    );
  });
};

export default {
  install: (app: App, options: InstallOptions) => {
    if (!app.config.globalProperties.$google) {
      // @ts-ignore
      app.config.globalProperties.$google = {};
    }

    app.config.globalProperties.$router.beforeEach((to, from, next) => {
      if (options.paths.find((path) => path === to.path)) {
        if (!document.querySelector('#google-maps-script')) {
          let script = document.createElement('script');
          script.id = 'google-maps-script';
          script.src = `https://maps.googleapis.com/maps/api/js?key=${options.apiKey}&libraries=places`;
          script.onload = () => next();
          script.onerror = () => console.error('Google Maps script failed to load');
          document.head.appendChild(script);
        } else {
          next();
        }
      } else {
        next();
      }
    });

    // Use this one if you want to have the google autocomplete dropdown
    app.config.globalProperties.$google.autoComplete = (options) => {
      const autocomplete = new window.google.maps.places.Autocomplete(options.input);

      autocomplete.setFields(['geometry', 'name', 'formatted_address']);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          console.log("No details available for input: '" + place.name + "'");
          return;
        }

        if (options.onPlaceChanged) {
          options.onPlaceChanged(place as Place);
        }
      });

      return autocomplete;
    };

    // Use this one if you want to make your own dropdown with the predictions
    app.config.globalProperties.$google.predictions = async (options) => {
      const autocomplete = new window.google.maps.places.AutocompleteService();
      autocomplete.getPlacePredictions({ input: options.input }, (predictions: Prediction[]) => {
        let predictionsWithLatLng: Promise<Place>[] = [];
        predictionsWithLatLng = predictions.map(async (prediction) => {
          return await getPlaceDetails(prediction.place_id);
        });
        Promise.all(predictionsWithLatLng).then((predictionsWithLatLng) => {
          if (options.onPredictions) {
            options.onPredictions(predictionsWithLatLng);
          }
        });
      });
      return autocomplete;
    };

    // provide for global access (composition api)
    const google = {
      autoComplete: app.config.globalProperties.$google.autoComplete,
      predictions: app.config.globalProperties.$google.predictions,
    };

    app.provide('google', google);
  },
};

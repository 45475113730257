import { createStore } from 'vuex';

import { state } from './state';
import Getters from './getters';
import Mutations from './mutations';
import Actions from './actions';

export const store = createStore({
  state,
  mutations: Mutations,
  actions: Actions,
  getters: Getters,
});

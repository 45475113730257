<template>
  <button :class="design" class="btn" :style="customStyle" @click="onClick">
    <slot />
  </button>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    design: {
      type: String,
      default: '',
    },
    css: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  computed: {
    customStyle() {
      let style = '';
      style += `width: ${this.width};height:${this.height};${this.css}`;
      return style;
    },
  },
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to);
      } else {
        this.$emit('click');
      }
    },
  },
});
</script>

<style lang="scss">
.btn {
  width: 150px;
  padding: 0.5em;
  border-radius: 30px;
  transition: 0.5s;
  cursor: pointer;
  border: none;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
}
.btn-black {
  background-color: #5a5a5a;
  color: white;

  &:hover {
    background-color: #6e6e6e;
  }
}
</style>

import type {
  PriceMetricsCompetitorsFilter,
  PriceMetricsRegionFilter,
} from '@/views/market/competitorTracker/priceComparison/priceMetrics/PriceMetrics';
import {
  GetInsightsOptions,
  GetMenuChangesOptions,
  PriceComparisonFilter,
  TimeFilter,
} from './endpoints.types';
import { SimplifiedFilter } from '@/utils/filters';
import { CompetitorRankingFilters } from '@/views/market/ranking/_competitorPosition';

export const AUTH = '/auth';

export const CHANGE_PASSWORD = '/users/password';

export const RESTAURANTS_USER = '/restaurants/user';

export const PLEEZ_REPORTS = '/pleezReports';

export const filtersToQueryParams = (_filter: SimplifiedFilter | undefined) => {
  if (!_filter) {
    return '';
  }

  // Build filter
  const mappedFilters = Object.entries(_filter)
    .filter((entry) => entry[1]?.length > 0)
    .map((entry) => `${entry[0]}:${entry[1].map((value) => encodeURIComponent(value)).join(',')}`);

  const filter = mappedFilters.join(';');

  const params = filter ? `filter=${filter}` : '';

  return params;
};

/**
 * Endpoints to get metrics
 */
export const METRICS = {
  RESTAURANT_OVERVIEW: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/restaurantOverview${queryString}`;
  },
  HEADLINES: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/headlines${queryString}`;
  },
  CATEGORIES_BREAKDOWN: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `${queryString ? '&' : '?'}fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/categoriesBreakdown${queryString}`;
  },
  WEEKDAY_SALES: ({ fromDate = '', toDate = '', categoryName = '', productName = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    if (categoryName) queryString += `${queryString ? '&' : '?'}CategoryName=${categoryName}`;

    if (productName) queryString += `${queryString ? '&' : '?'}ProductName=${productName}`;

    return `/metrics/weekdaySales${queryString}`;
  },
  NON_SELLING: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/nonSelling${queryString}`;
  },
  TOP_SELLERS: ({ fromDate = '', toDate = '' }) => {
    let queryString = '';

    if (fromDate) queryString += `?fromDate=${fromDate}`;

    if (toDate) queryString += `${queryString ? '&' : '?'}toDate=${toDate}`;

    return `/metrics/topSellers${queryString}`;
  },
  RESTAURANT_ORDERS: ({ fromDate, toDate, periodType }: TimeFilter) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/orders${queryString}`;
  },
  RESTAURANT_SALES: ({ fromDate, toDate, periodType }: TimeFilter) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/sales${queryString}`;
  },
  RESTAURANT_ITEMS: ({ fromDate, toDate, periodType }: TimeFilter) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/items${queryString}`;
  },
  RESTAURANT_AVERAGE_TICKET: ({ fromDate, toDate, periodType }: TimeFilter) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/averageTicket${queryString}`;
  },
  MENU_QUALITY: '/menuEng',
  VISIBILITY: ({ fromDate, toDate, periodType }: TimeFilter) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/visibility${queryString}`;
  },
  CONVERSION_RATE: ({ fromDate, toDate, periodType }: TimeFilter) => {
    const queryString = `?fromDate=${fromDate}&toDate=${toDate}&periodType=${periodType}`;

    return `/metrics/conversion${queryString}`;
  },

  // New analytics endpoints
  RESTAURANTS: {
    OVERVIEW: (_filter: SimplifiedFilter) => {
      const params = '?' + filtersToQueryParams(_filter);

      return `/metrics/new/restaurants/overview${params}`;
    },
    OVERVIEW_BY_RESTAURANT: (filters: URLSearchParams) => {
      return `/metrics/new/restaurants/byRestaurant?${filters.toString()}`;
    },
    OVERVIEW_BY_PLATFORM: (filters: URLSearchParams) => {
      return `/metrics/new/restaurants/byPlatform?${filters.toString()}`;
    },
  },
};

export const MARKET_TRENDS = {
  GET_ESTIMATED_ORDERS: (_filter: SimplifiedFilter) => {
    const params = '?' + filtersToQueryParams(_filter);

    return `/marketTrends/getEstimatedOrders${params}`;
  },
};

export const MENU = {
  CATEGORIES: ({ idRestaurants = '' }) => {
    let queryString = '';

    if (idRestaurants) queryString += `?idRestaurants=${idRestaurants}`;

    return `/categories${queryString}`;
  },
  CATEGORIES_LABELS: ({ idRestaurants = '' }) => {
    let queryString = '';

    if (idRestaurants) queryString += `?idRestaurants=${idRestaurants}`;

    return `/categories/labels${queryString}`;
  },
  PRODUCTS: ({ categoryName, idMenu = '' }: { categoryName: string; idMenu: string }) => {
    let queryString = '';

    if (idMenu) queryString += `?idMenu=${idMenu}`;

    return `/products/${categoryName}${queryString}`;
  },
  PRODUCTS_ROOT: ({ idMenu = '' }) => {
    let queryString = '';

    if (idMenu) queryString += `?idMenu=${idMenu}`;

    return `/products/${queryString}`;
  },
  FUNCTIONS: '/functions',
  MENU_FUNCTIONS: () => {
    return `/functions/listFunctions`;
  },
};

export const PRICE_COMPARISON = {
  ROOT: (searchParams: URLSearchParams) => {
    return `/priceComparison?${searchParams}`;
  },
  SALES_ANALYSIS_PER_CATEGORY: ({
    idRestaurant,
    categoryLabel = '',
    Label = '',
    keywords = '',
  }: PriceComparisonFilter) => {
    let queryString = '';

    if (idRestaurant) queryString += `?idRestaurants=${idRestaurant}`;

    if (categoryLabel) queryString += `${queryString ? '&' : '?'}categoryLabel=${categoryLabel}`;

    if (Label) queryString += `${queryString ? '&' : '?'}Label=${Label}`;

    if (keywords) queryString += `${queryString ? '&' : '?'}keywords=${keywords}`;

    return `/priceComparison/categorySalesAnalysis/${queryString}`;
  },
  FILTERS: '/priceComparison/filters/competitorsMap',
  MORE_FILTERS: (filters?: SimplifiedFilter) => {
    return `/priceComparison/filters/competitorsMap/more?${filtersToQueryParams(filters)}`;
  },
  SUGGESTED_COMPETITORS: (filters?: SimplifiedFilter) => {
    return `/competitors?${filtersToQueryParams(filters)}`;
  },
  SUGGESTED_COMPETITORS_SAVED: (filters?: SimplifiedFilter) => {
    return `/competitors/saved?${filtersToQueryParams(filters)}`;
  },
  CATEGORIES: (filters?: SimplifiedFilter) => {
    return `/priceComparison/filters/priceComparison/categories?${filtersToQueryParams(filters)}`;
  },
  ITEMS: (filters?: SimplifiedFilter) => {
    return `/priceComparison/filters/priceComparison/items?${filtersToQueryParams(filters)}`;
  },
  PRICE_METRICS_REGION: (filters: PriceMetricsRegionFilter) => {
    let url = `/priceComparison/priceMetrics/region?`;

    // Add radius to query
    url += `radius=${filters.radius}`;

    // Add restaurant ID to query
    url += `&idRestaurant=${filters.idRestaurant}`;

    // If categories, add to query
    if (filters.categories?.length) {
      const categories = filters.categories.map((cat) => encodeURIComponent(cat));
      url += `&categories=${categories.join(',')}`;
    }

    // If item IDs, add to query
    if (filters.idItems?.length) {
      url += `&idItems=${filters.idItems.join(',')}`;
    }

    if (filters.restaurantType?.length) {
      url += `&restaurantType=${filters.restaurantType.join(',')}`;
    }

    // Add idSource to query
    url += `&idSource=${filters.platformId}`;

    return url;
  },
  PRICE_METRICS_COMPETITORS: (filters: PriceMetricsCompetitorsFilter) => {
    let url = `/priceComparison/priceMetrics/competitors?`;

    // Add idRestaurants to query
    url += `idRestaurants=${filters.idRestaurants.join(',')}`;

    // If categories, add to query
    if (filters.categories.length) {
      const categories = filters.categories.map((cat) => encodeURIComponent(cat));
      url += `&categories=${categories.join(',')}`;
    }

    // If item IDs, add to query
    if (filters.idItems.length) {
      url += `&idItems=${filters.idItems.join(',')}`;
    }

    // Add idSource to query
    url += `&idSource=${filters.platformId}`;

    return url;
  },
  COMPETITOR_RANKING: (filters: CompetitorRankingFilters) => {
    const params = new URLSearchParams();
    params.append('lat', filters.location.lat.toString());
    params.append('long', filters.location.lng.toString());
    params.append('platformId', filters.platform?.id.toString()!);
    params.append('query', filters.restaurantType);
    return `/v2/ranking/restaurant?${params.toString()}`;
  },
  TAGS: (filters: { platformId: number }) =>
    `/v2/tag?platformId=${filters.platformId}&forUser=true`,
};

export const SALES = {
  DATE_RANGE: '/reports/dateRange',
};

export const HOME = '/home';

export const RESTAURANTS_PLATFORMS = '/users/restaurantsAndPlatforms';
export const CITIES_PLATFORMS = '/users/citiesAndPlatforms';

export const FUNCTIONS = {
  LIST_USER_FUNCTIONS: (options: GetMenuChangesOptions) => {
    const { filter: filter, sort, order, limit, offset } = options;

    const params = [`order=${order}`];

    params.push(filtersToQueryParams(filter));

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (offset) {
      params.push(`offset=${offset}`);
    }

    if (limit) {
      params.push(`limit=${limit}`);
    }

    return `/functions/listUserFunctions?${params.join('&')}`;
  },
};

export const INSIGHTS = {
  ROOT: (options: GetInsightsOptions) => {
    const { filter: filter, sort, order, limit, offset } = options;

    const params = [`order=${order}`];
    if (typeof filter !== 'string') {
      params.push(filtersToQueryParams(filter));
    } else if (filter) {
      params.push(`filter=${filter}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (offset) {
      params.push(`offset=${offset}`);
    }

    if (limit) {
      params.push(`limit=${limit}`);
    }

    return `/insights?${params.join('&')}`;
  },
  TYPES: '/insights/types',
  SETTINGS: '/insights/settings',
};

export const MARKET = {
  PROMOTIONS: '/promotions',
  PROMOTIONS_TYPES: '/promotions/types',
  PLATFORMS: '/platforms',
};

import translations from './translations.json';
import { store } from '@/store/v1';
export const getTranslation = (labelPath: string): string => {
  const currentLanguage = store.getters.currentLanguage;
  try {
    // Get translation object from path
    // TODO: Terrible typing, but solving typing here is awful
    let currLayer: any = translations; // eslint-disable-line @typescript-eslint/no-explicit-any
    const splitPath = labelPath.split('.');
    for (const child of splitPath) {
      // Due to bad typing, we increase safety when accessing the object
      if (currLayer && typeof currLayer === 'object' && child in currLayer) {
        currLayer = currLayer[child];
      } else {
        console.error(`Couldn't find ${labelPath} in translation file`);

        return '';
      }
    }

    // Get translated string
    let translateLabel: string;
    if (currentLanguage in currLayer) {
      translateLabel = currLayer[currentLanguage];
    } else {
      console.error(`Couldn't find ${currentLanguage} translation for ${labelPath}`);

      return '';
    }

    if (!translateLabel) {
      // Language translation not found at path level, log error
      console.error(`Couldn't find ${currentLanguage} translation for ${labelPath}`);

      return '';
    }

    return translateLabel;
  } catch (error) {
    console.error(`Couldn't find ${currentLanguage} translation for ${labelPath}`);
  }

  return '';
};

export const replaceTemplate = (template: string, templateArguments: string[]): string => {
  return (
    templateArguments?.reduce((acc, curr, index) => acc.replace(`$${index}`, curr), template) ??
    template
  );
};

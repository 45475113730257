export enum PlatformNames {
  UberEats = 'UberEats',
  Glovo = 'Glovo',
  JustEat = 'JustEat',
  Bolt = 'Bolt',
  Deliveroo = 'Deliveroo',
}

export enum ViewPort {
  Desktop = 1,
  SmallDesktop = 2,
  Tablet = 3,
  Mobile = 4,
}

export enum CustomElement {
  Button = 'button',
  Changes = 'changes',
  Checkbox = 'checkbox',
  Download = 'download',
  Date = 'date',
  Description = 'description',
  Icon = 'Icon',
  Platform = 'platform',
  Restaurant = 'Restaurant',
  Status = 'status',
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}
import { ViewPort } from './_enums/global';

export const viewPort = ViewPort;

export const mobileMaxWidth = 425;
export const tabletMaxWidth = 768;
export const smallDesktopMinWidth = 1024;
export const desktopMinWidth = 1400;

export const primaryColorHex = '#232859';
export const secondaryColorHex = '#9d44fc';
export const tertiaryColorHex = '#ff6248';
export const textSecondary2ColorHex = '#000';

export const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue(
  '--brand-secondary-rgb',
);

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const countryCodes = {
  PT: 351,
  ES: 34,
  GB: 44,
  AE: 971,
  AD: 376,
  AG: 1268,
  AI: 1264,
  AM: 374,
  AO: 244,
  AR: 54,
  AU: 61,
  AT: 43,
  AW: 297,
  AZ: 994,
  BA: 387,
  BB: 1246,
  BD: 880,
  BE: 32,
  BF: 226,
  BG: 359,
  BH: 973,
  BI: 257,
  BJ: 229,
  BM: 1441,
  BN: 673,
  BO: 591,
  BR: 55,
  BS: 1242,
  BT: 975,
  BW: 267,
  BY: 375,
  BZ: 501,
  CA: 1,
  CF: 236,
  CG: 242,
  CH: 41,
  CK: 682,
  CL: 56,
  CM: 237,
  CN: 86,
  CO: 57,
  CR: 506,
  CS: 381,
  CU: 53,
  CV: 238,
  CY: 357,
  CZ: 42,
  DE: 49,
  DJ: 253,
  DK: 45,
  DM: 1809,
  DO: 1809,
  DZ: 213,
  EC: 593,
  EE: 372,
  EG: 20,
  ER: 291,
  ET: 251,
  FI: 358,
  FJ: 679,
  FK: 500,
  FM: 691,
  FO: 298,
  FR: 33,
  GA: 241,
  GD: 1473,
  GE: 995,
  GF: 594,
  GH: 233,
  GI: 350,
  GL: 299,
  GM: 220,
  GN: 224,
  GP: 590,
  GQ: 240,
  GR: 30,
  GT: 502,
  GU: 671,
  GW: 245,
  GY: 592,
  HK: 852,
  HN: 504,
  HR: 385,
  HT: 509,
  HU: 36,
  ID: 62,
  IE: 353,
  IL: 972,
  IN: 91,
  IQ: 964,
  IR: 98,
  IS: 354,
  IT: 39,
  JM: 1876,
  JO: 962,
  JP: 81,
  KE: 254,
  KG: 996,
  KH: 855,
  KI: 686,
  KM: 269,
  KN: 1869,
  KP: 850,
  KR: 82,
  KW: 965,
  KY: 1345,
  KZ: 7,
  LA: 856,
  LB: 961,
  LI: 417,
  LK: 94,
  LR: 231,
  LS: 266,
  LT: 370,
  LU: 352,
  LV: 371,
  LY: 218,
  MA: 212,
  MC: 377,
  MD: 373,
  MG: 261,
  MH: 692,
  MK: 389,
  ML: 223,
  MN: 95,
  MNG: 976,
  MO: 853,
  MQ: 596,
  MR: 222,
  MS: 1664,
  MT: 356,
  MV: 960,
  MW: 265,
  MX: 52,
  MY: 60,
  MZ: 258,
  NA: 264,
  NC: 687,
  NE: 227,
  NF: 672,
  NG: 234,
  NI: 505,
  NL: 31,
  NO: 47,
  NP: 977,
  NPM: 670,
  NR: 674,
  NU: 683,
  NZ: 64,
  OM: 968,
  PA: 507,
  PE: 51,
  PF: 689,
  PG: 675,
  PH: 63,
  PL: 48,
  PR: 1787,
  PW: 680,
  PY: 595,
  QA: 974,
  RE: 262,
  RO: 40,
  RU: 7,
  RW: 250,
  SA: 966,
  SB: 677,
  SC: 248,
  SD: 249,
  SE: 46,
  SG: 65,
  SH: 290,
  SI: 386,
  SK: 421,
  SL: 232,
  SM: 378,
  SN: 221,
  SO: 252,
  SR: 597,
  ST: 239,
  STC: 1758,
  SV: 503,
  SY: 963,
  SZ: 268,
  TC: 1649,
  TG: 228,
  TH: 66,
  TJ: 7,
  TM: 993,
  TN: 216,
  TO: 676,
  TR: 90,
  TT: 1868,
  TV: 688,
  TW: 886,
  UA: 380,
  UG: 256,
  US: 1,
  UY: 598,
  UZ: 7,
  VA: 379,
  VE: 58,
  VG: 1284,
  VN: 84,
  VU: 678,
  WF: 681,
  YE: 969,
  YT: 269,
  ZA: 27,
  ZM: 260,
  ZW: 263,
};

export enum CommonFiltersComponentTypes {
  Select = 'Select',
  Range = 'Range',
  CheckboxAction = 'CheckboxAction',
}

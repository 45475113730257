import { Mutations } from '@/store/v1/store';

const Mutations: Mutations = {
  setPlatform: function (state, id) {
    state.subHeaderSelectedPlatform = id;
  },
  setAllPlatforms: function (state, allPlatformsSelected) {
    state.allPlatformsSelected = allPlatformsSelected;
  },
  setSelectedProduct: function (state, product) {
    state.subHeaderSelectedProduct = product;
  },
  setSelectedRestaurant: function (state, restaurant) {
    state.headerSelectedRestaurant = restaurant;
  },
  setRestaurantFilter(state, restaurantFilter) {
    state.restaurantFilters = {
      restaurants: restaurantFilter.restaurants || [],
      labels: restaurantFilter.labels || [],
      keywords: restaurantFilter.keywords || [],
    };
  },
  setRestaurantPlatforms(state, restaurantPlatforms) {
    state.restaurantPlatforms = restaurantPlatforms;
  },
  setCriticalError(state, section) {
    state.criticalErrors[section] = true;
  },
  setUserOrdersDates(state, dateObject) {
    state.selectedDateRange = dateObject.dateRange;
    state.subHeaderSelectedDate = dateObject.selectedDate;
    state.userOrderDates = dateObject.orderDates || state.userOrderDates;
  },
  setUserRestaurants(state, restaurants) {
    state.restaurants = restaurants;
  },
  setPleezInsights: (state, insights) => {
    state.pleezInsights = insights;
  },
  setNewViewPort: (state, viewportWidth) => {
    state.viewportWidth = viewportWidth;
  },
  setCommonFilters: async (state, { allRestaurants: addresses, platforms }) => {
    state.commonFilters = {
      addresses,
      platforms,
    };
  },
  setCityAndPlatforms: async (state, { cities, platforms, defaultCity }) => {
    state.cityAndPlatforms = {
      cities,
      platforms,
      defaultCity,
    };
  },
  setPriceComparisonCompetitorFilters: async (
    state,
    { sources, defaultSource, allRestaurants, defaultRestaurant },
  ) => {
    state.priceComparisonCompetitorFilters = {
      sources,
      defaultSource,
      allRestaurants,
      defaultRestaurant,
    };
  },
  setSelectedLanguage: (state, language) => {
    state.selectedLanguage = language;
  },
};

export default Mutations;
